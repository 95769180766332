<template>
  <div
    class="
      min-h-screen
      flex
      items-center
      justify-center
      bg-gray-50
      py-12
      px-4
      sm:px-6
      lg:px-8
      flex-col
    "
  >
    <div class="max-w-md w-full space-y-8">
      <div>
        <img class="mx-auto h-16 w-auto" src="/img/logo.svg" alt="Workflow" />
        <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
          {{ $t("Forgot password") }}
        </h2>
        <p class="mt-2 text-center text-sm text-gray-600">
          {{ $t("Or") }}
          {{ " " }}
          <router-link
            to="/login"
            class="font-medium text-fuchsia-600 hover:text-fuchsia-500"
          >
            {{ $t("Sign in to your account") }}
          </router-link>
        </p>
      </div>
      <form class="space-y-2" @submit.prevent="ForgetPassword">
        <div>
          <label for="email" class="block text-sm font-medium text-gray-700">
            {{ $t("User name") }}
          </label>
          <div class="mt-1">
            <input
              id="email"
              name="email"
              autocomplete="username"
              v-model="user.username"
              class="
                appearance-none
                block
                w-full
                px-3
                py-2
                border border-gray-300
                rounded-md
                shadow-sm
                placeholder-gray-400
                focus:outline-none
                focus:ring-fuchsia-500
                focus:border-fuchsia-500
                sm:text-sm
              "
            />
          </div>
        </div>
        <div class="text-center">
          <span>{{ $t("or") }}</span>
        </div>
        <div>
          <label for="email" class="block text-sm font-medium text-gray-700">
            {{ $t("Email address") }}
          </label>
          <div class="mt-1">
            <input
              id="email"
              name="email"
              type="email"
              autocomplete="email"
              v-model="user.email"
              class="
                appearance-none
                block
                w-full
                px-3
                py-2
                border border-gray-300
                rounded-md
                shadow-sm
                placeholder-gray-400
                focus:outline-none
                focus:ring-fuchsia-500
                focus:border-fuchsia-500
                sm:text-sm
              "
            />
          </div>
        </div>
        <div>
          <button
            type="submit"
            class="
              group
              relative
              w-full
              flex
              justify-center
              py-2
              px-4
              border border-transparent
              text-sm
              font-medium
              rounded-md
              text-white
              bg-fuchsia-600
              hover:bg-fuchsia-700
              focus:outline-none
              focus:ring-2
              focus:ring-offset-2
              focus:ring-fuchsia-500
            "
          >
            <span class="absolute left-0 inset-y-0 flex items-center pl-3">
              <LockClosedIcon
                class="h-5 w-5 text-fuchsia-500 group-hover:text-fuchsia-400"
                aria-hidden="true"
              />
            </span>
            {{ $t("Send") }}
          </button>
        </div>
      </form>
    </div>
    <Footer />
  </div>
</template>

<script>
import { LockClosedIcon } from "@heroicons/vue/solid";
import Footer from "../components/footer.vue";
export default {
  components: {
    LockClosedIcon,
    Footer,
  },
  data() {
    return {
      user: {
        language: localStorage.getItem("haushaltsLang")
          ? localStorage.getItem("haushaltsLang")
          : "de",
      },
    };
  },
  methods: {
    ForgetPassword() {
      if (this.user.email == null && this.user.username == null) {
        this.$emit("showNotif", {
          type: "error",
          msg: this.$t("please fill email or username"),
          showNotif: true,
        });
        return;
      }

      if (this.user.email == "") {
        this.user = {
          username: this.user.username,
        };
      }
      if (this.user.username == "") {
        this.user = {
          email: this.user.email,
        };
      }

      this.$store.dispatch("User/ForgetPassword", this.user).then((res) => {
        this.$emit("showNotif", {
          type: res.type,
          msg: this.$t(res.msg),
          showNotif: true,
        });
      });
    },
  },
};
</script>
